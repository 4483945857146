@import 'foundation/scss/foundation';
body { background: #D5D0F9;}

.name img {
  width: 70px;
  height: 40px;
  position: relative;
  z-index: 999;
  display: block;
  margin: auto; }

.chosen-container {
  margin-bottom: 15px; }

input.small {
  width: 60px; }

input.medium {
  width: 120px; }

.loginreg {

  padding: 40px 40px 40px 40px;
  width: 500px;
  height:600px;
  background-color: #eaeaea;
  margin: 10px auto 0px;
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.loginreg h1 {
  font-weight: 100;
  font-size: 2.3em;
  margin-left:15px;
}

.loginreg input[type=submit] {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

.loginreg input[type=email], input[type=password], input[type=name] {
  height: 35px;
  font-size: 16px;
  width: 300px;
  margin-bottom: 10px;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top: 1px solid #c0c0c0;
  /* border-radius: 2px; */
  padding: 0 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.loginreg input[type=email]:hover, input[type=password]:hover, input[type=name]:hover {
  border: 1px solid #b9b9b9;
  border-top: 1px solid #a0a0a0;
  -moz-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
}

.button2
{
margin-left: 20px;
}

.fp
{
margin-left: 20px;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

 .name img{
  width: 70px;
  height: 40px;
  position: relative;
  z-index: 999;
  display: block;
  margin:auto;
}

.rd-back{color: #000;}
.rd-next{color: #000;}


/*# sourceMappingURL=app.css.map */
